.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .test {
    width: 300px;
    height: 350px;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  }
  .c {
    padding: 20px;
    background: white;
    border-radius: 5px;
  }
  